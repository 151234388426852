<template>
  <div
    class="footer--head__container bg-black text-white md:text-left text-center"
  >
    <div class="footer--head--content md:py-32 py-24">
      <div
        class="top--content flex flex-wrap lg:w-auto w-11/12 mr-auto ml-auto"
      >
        <div class="left--content md:w-1/2">
          <div class="content lg:w-4/5 xl:w-3/5 mr-auto ml-auto">
            <h1
              class="
                md:text-4xl
                text-3xl
                font-merriweather font-bold
                md:leading-relaxed
                leading-normal
              "
            >
              I spend most of my time building and deploying.
            </h1>
            <p class="mt-8 md:text-xl text-lg text-gray-300">
              You'll find me lurking around GitHub most of the time. If not I'd
              probably be checking the r/mk subreddit.
            </p>
            <div class="link mt-8 text-lg">
              <a
                href="https://github.com/deepjyoti30"
                target="_blank"
                rel="noopener noreferrer"
                class="link-base"
                >Check my GitHub =></a
              >
            </div>
          </div>
        </div>
        <div class="right--content md:w-1/2 md:mt-0 mt-16">
          <div class="numbers">
            <number-stat v-if="areDownloadsFetched" :stat="getDownloadStat" />
            <number-stat
              class="
                lg:ml-12
                lg:pl-12
                lg:border-l-2
                lg:mt-0
                mt-16
                border-gray-800
              "
              :stat="getViewCount"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NumberStat from "./NumberStat.vue";
import { packages } from "@/mixins/packages.js";
import { number } from "@/mixins/number.js";

export default {
  name: "FooterHead",
  components: { NumberStat },
  mixins: [packages, number],
  data() {
    return {
      areDownloadsFetched: false,
      packages: ["ytmdl", "downloader-cli", "simber", "QuickWall"],
      totalDownloads: 0,
    };
  },
  methods: {
    fetchTotalDownloads: async function () {
      /**
       * Fetch the total number of downloads for the packages
       */
      this.totalDownloads = await this.getTotalDownloads(this.packages);
      this.areDownloadsFetched = true;
    },
  },
  computed: {
    getDownloadStat() {
      return {
        text: "package downloads",
        number: this.formatNumber(this.totalDownloads, 0),
      };
    },
    getViewCount() {
      return {
        text: "Webapps used",
        number: this.formatNumber(378942, 0),
      };
    },
  },
  async mounted() {
    await this.fetchTotalDownloads();
  },
};
</script>
