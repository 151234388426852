<template>
  <div class="footer__container bg-black text-white">
    <div class="footer--content">
      <div class="head">
        <footer-head />
      </div>
      <div
        class="
          border-t border-gray-800
          pt-8
          pb-16
          xl:w-4/5
          w-11/12
          mr-auto
          ml-auto
        "
      >
        <div
          class="
            content
            flex
            md:flex-row
            flex-col
            md:text-left
            text-center
            justify-between
          "
        >
          <div class="left--content logo">
            <img
              src="img/logo_white.png"
              alt="Logo"
              class="md:w-20 w-16 md:mr-0 md:ml-0 mr-auto ml-auto"
            />
            <div class="text">
              <h1 class="text-xl mt-4 font-medium">Deepjyoti Barman</h1>
              <p class="mt-2 text-gray-400">2021 - Present</p>
            </div>
          </div>
          <div class="right--content md:w-1/2 md:mt-0 mt-6">
            <div
              class="links--content flex md:flex-row flex-col justify-around"
            >
              <footer-links
                v-for="(value, id) in getLinks"
                :key="id"
                class="md:mt-0 mt-8"
                :linkData="value"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FooterHead from "./FooterHead.vue";
import FooterLinks from "./FooterLinks.vue";

export default {
  components: { FooterHead, FooterLinks },
  name: "Footer",
  computed: {
    getLinks() {
      return [
        {
          head: "Social",
          links: [
            { link: "https://github.com/deepjyoti30", title: "GitHub" },
            { link: "https://twitter.com/DeepBarman30", title: "Twitter" },
            {
              link: "https://www.linkedin.com/in/deepjyoti-barman-8a2257151/",
              title: "LinkedIn",
            },
          ],
        },
        {
          head: "Sitemap",
          links: [
            { link: "/", title: "About", is_router: true },
            { link: "/works", title: "Works", is_router: true },
            { link: "/achievements", title: "Achievements", is_router: true },
            { link: "/uses", title: "Uses", is_router: true },
          ],
        },
        {
          head: "Others",
          links: [
            { link: "mailto:hello@deepjyoti30.dev", title: "Contact" },
            { link: "https://blog.deepjyoti30.dev", title: "Blog" },
            {
              link: "https://deepjyoti30.dev/privacy-policy",
              title: "Privacy",
            },
          ],
        },
      ];
    },
  },
};
</script>
