export const number = {
  methods: {
    formatNumber: function (number, offset = 2) {
      /**
       * If the stars are more than thousand then
       * convert them into more readable values.
       */
      const starsInt = parseInt(number);
      if (starsInt < 1000) return number;
      // Else we need to convert it.
      const base = parseFloat(starsInt / 1000).toFixed(offset);
      return `${base}k`;
    },
  },
};
