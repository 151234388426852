<template>
  <div class="footer--links__container">
    <div class="footer--links">
      <h1 class="heading md:text-xl text-lg font-medium">
        {{ getLinkHeading }}
      </h1>
      <div class="links mt-6 text-gray-300 font-jetbrains-mono">
        <component
          :is="value.is_router ? 'router-link' : 'a'"
          :target="value.is_router ? '' : '_blank'"
          rel="noopener noreferrer"
          v-for="(value, id) in getLinks"
          :key="id"
          :to="value.link"
          :href="value.link"
          class="block mt-3 link-highlight"
          >{{ value.title }}
          <span v-if="!value.is_router"> =></span>
        </component>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterLinks",
  props: {
    linkData: {
      type: Object,
      require: true,
    },
  },
  computed: {
    getLinkHeading() {
      return this.linkData.head;
    },
    getLinks() {
      return this.linkData.links;
    },
  },
};
</script>
