export const packages = {
  data() {
    return {
      fetchEndpoint: "https://api.pepy.tech/api/v2/projects/",
    };
  },
  methods: {
    getDownloads: async function (packageName) {
      /**
       * Get the number of the downloads for the passed package.
       *
       * We will use pepy's API in order to fetch the downloads.
       * pepy.tech
       */
      const URL = this.fetchEndpoint + packageName;
      const response = await fetch(URL);

      // Check if an error has occurred
      if (response.status >= 400 && response.status < 600) return 0;

      const json = await response.json();
      return parseInt(json["total_downloads"]);
    },
    getTotalDownloads: async function (packages) {
      /**
       * Get the downloads for each of the packages
       * passed and accordingly add them and return the total
       * number of downloads.
       */
      var totalDownloads = 0;

      for (const packageName of packages) {
        totalDownloads += await this.getDownloads(packageName);
      }

      return totalDownloads;
    },
  },
};
