<template>
  <div class="number--stat__container inline-block">
    <div class="number--stat--content">
      <div class="left--count">
        <div class="top--text text-lg font-medium capitalize">
          { {{ getText }} }
        </div>
        <div class="number mt-8">
          <h1 class="text-7xl font-roboto-mono text-teal">{{ getNumber }}</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NumberStat",
  props: {
    stat: {
      type: Object,
      require: true,
    },
  },
  computed: {
    getText() {
      return this.stat.text;
    },
    getNumber() {
      return this.stat.number;
    },
  },
};
</script>
